@import url("../abstracts/theme.less");

.page-footer {
  background-color: var(--footer-background);
  width: 100%;
  clear: both;
  font-size: var(--font-size-sm);
}

.page-footer .container-fluid {
  padding: 0;
}

.page-footer__flex-row {
  min-height: 3.5rem;
  display: block;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.page-footer__flex-row > *:not(script) {
  padding: 0.5rem 1.25rem;
  display: flex;
  align-items: center;
}

.page-footer__footer-id-placeholder {
  flex: 1;
  flex-wrap: wrap;
}

.page-footer span {
  display: inline-block;
}

.page-footer__links {
  &:not(:last-child) {
    border-right: 1px solid var(--breadcrumbs-bg);
  }
}

.page-footer a {
  .link-dark();

  font-weight: 600;
}
