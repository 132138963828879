.app-icon-legend {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: calc(var(--section-padding) / 2);
  align-items: center;
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-bottom: var(--section-padding);
  }

  dt {
    display: flex;
    align-items: center;
    justify-items: center;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  dd {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
}
