@min-button-size: 36px;

.jenkins-icon-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;

  &__items {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    ol {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style-type: none;
      gap: 0.25rem;

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 0.8rem;
        background: var(--button-background);
        opacity: 0;
        transition: var(--standard-transition);
      }

      &:hover {
        &::before {
          inset: -0.2rem;
          opacity: 1;
        }
      }
    }

    li {
      .jenkins-button {
        margin: 0 !important;
        padding: 0 !important;
        min-width: 0 !important;
        aspect-ratio: 1;
        height: @min-button-size;
      }
    }

    .jenkins-icon-size__items-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      height: @min-button-size;
      border-radius: 10px;
      font-weight: var(--btn-link-font-weight);
      font-size: var(--btn-font-size);
      background: var(--button-background--hover);
      cursor: default;
    }
  }
}
