@import url("../abstracts/theme.less");

.icon-help {
  color: var(--primary);

  &:hover,
  &:focus {
    color: var(--primary-hover);
    border-color: var(--primary-hover);
  }
}

.icon-small {
  width: 16px;
  height: 16px;
}

.icon-medium {
  width: 24px;
  height: 24px;
}

.icon-large {
  width: 32px;
  height: 32px;
}

.icon-xlarge {
  width: 64px;
  height: 64px;
}

/*****
 * Build status icons
 *****/

.animated-spin,
.icon-blue-anime,
.icon-red-anime {
  will-change: transform;
  // animation: blink 1s infinite linear;
}

// Mixin to set icon colors
.status-icon-color(@color, @opacity: 1) {
  color: @color;
  fill: @color;
  opacity: @opacity;

  .svg-icon {
    color: @color;
    fill: @color;
  }
}

.icon-blue,
.icon-blue-anime {
  .status-icon-color(var(--success));
}

.icon-red,
.icon-red-anime {
  .status-icon-color(var(--danger));
}

.icon-yellow,
.icon-yellow-anime {
  .status-icon-color(var(--unstable-build-icon-color));
}

.icon-aborted,
.icon-aborted-anime {
  .status-icon-color(var(--black));
}

.icon-disabled,
.icon-disabled-anime {
  .status-icon-color(var(--black), 0.5);
}

.icon-grey,
.icon-grey-anime,
.icon-nobuilt,
.icon-nobuilt-anime {
  .status-icon-color(var(--primary));
}

.build-status-icon__wrapper {
  display: inline-flex;
  position: relative;
}

.build-status-icon__outer {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  .svg-icon {
    width: 100%;
    height: 100%;
  }
}

.icon-blue-anime .build-status-icon__outer,
.icon-red-anime .build-status-icon__outer,
.icon-yellow-anime .build-status-icon__outer,
.icon-aborted-anime .build-status-icon__outer,
.icon-disabled-anime .build-status-icon__outer,
.icon-grey-anime .build-status-icon__outer,
.icon-nobuilt-anime .build-status-icon__outer {
  .svg-icon {
    animation: spin 1.7s linear infinite;
  }
}

@keyframes pulse-animation {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.pulse-animation {
  svg {
    animation: pulse-animation 2s ease infinite;
  }
}
