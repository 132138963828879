.jenkins-app-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--section-padding);

  .jenkins-app-bar__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 36px;
  }

  .jenkins-app-bar__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--section-padding);
    min-height: 36px;
    gap: 1rem;

    .jenkins-search {
      min-width: 260px;
    }
  }

  &--border {
    margin-bottom: var(--section-padding);
    padding-bottom: var(--section-padding);
    border-bottom: 2px solid var(--panel-border-color);
  }

  &--sticky {
    position: sticky;
    top: 40px;
    padding-top: 2rem;
    margin-top: -2rem;
    z-index: 2;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -2rem;
      bottom: -2rem;
      right: -2rem;
      z-index: -1;
      pointer-events: none;
    }

    &::before {
      background: var(--background);
      mask-image: linear-gradient(black 70%, transparent);
      opacity: 0.55;

      @supports not (backdrop-filter: blur(15px)) {
        opacity: 1;
      }
    }

    &::after {
      backdrop-filter: blur(15px);
      mask-image: linear-gradient(black 50%, transparent);
    }
  }

  h1,
  h2 {
    margin: 0;
    font-size: 1.6rem;
  }
}
